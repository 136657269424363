import React from 'react';
import {
  useTranslation,
  Trans,
} from 'react-i18next';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { getPurposeItems } from '../../helpers/analysis';
import Select from '../Select';
import RatesEditor from '../RatesEditor';
import InfoMessage from '../Messages/InfoMessage';

import './index.scss';

const ZonesMapEditorPanel = ({
  className,
  areaUnit,
  colors,
  zonesMapGeojson,
  type,
  area,
  selectedZone,
  onTypeChange,
  onAttributesChange,
  onSelectedZoneChange,
}) => {
  const [purposeItems] = React.useState(getPurposeItems());
  const { t } = useTranslation();
  const panelRef = React.createRef();

  const handleClickAway = (event) => {
    if (!panelRef.current.contains(event.target)) {
      return;
    }

    onSelectedZoneChange(null);
  };

  return (
    <div
      ref={panelRef}
      className={clsx('zones-map-editor-panel', className)}
    >
      <div className="zones-map-editor-panel__description">
        <Typography className="zones-map-editor-panel__description-text">
          {t('zones-map.rates.description.p1')}
        </Typography>
        <Typography className="zones-map-editor-panel__description-text">
          {t('zones-map.rates.description.p2')}
        </Typography>
        <Typography className="zones-map-editor-panel__description-text">
          {t('zones-map.rates.description.p3')}
        </Typography>
      </div>
      <Select
        id="typeSelect"
        value={type}
        label={t('general.controls.purpose')}
        options={purposeItems}
        classes={{
          root: 'zones-map-editor-panel__type-select',
        }}
        onChange={onTypeChange}
      />
      <InfoMessage classes={{
        root: 'zones-map-editor-panel__rates-requirements',
      }}
      >
        <Trans i18nKey="zones-map.rates.rates-requirements">
          The <span className="zones-map-editor-panel__rates-requirements-text_bold">product title</span>
          should consist of Latin characters, digits or underscores (max 10).
          Maximum <span className="zones-map-editor-panel__rates-requirements-text_bold">product value</span> is 1,000,000.
        </Trans>
      </InfoMessage>
      <ClickAwayListener onClickAway={handleClickAway}>
        <RatesEditor
          areaUnit={areaUnit}
          attributes={zonesMapGeojson}
          colors={colors}
          totalArea={area}
          selectedZone={selectedZone}
          onAttributesChange={onAttributesChange}
          onSelectedZoneChange={onSelectedZoneChange}
        />
      </ClickAwayListener>
    </div>
  );
};

export default ZonesMapEditorPanel;
